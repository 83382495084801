<template>
  <el-card>
    <div slot="header">
      <span>客户管理</span>
    </div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
          v-model="queryFilter.keyWorlds"
          placeholder="请输入用户相关信息"
          clearable
        />
      </el-col>
      <el-button type="primary" @click="flashTable">查询用户</el-button>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table :data="userListData" stripe style="width: 100%" border>
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="userName" label="用户名"> </el-table-column>
        <el-table-column label="客户备注名称">
          <template slot-scope="scope">
            <span>{{ scope.row.customerName }}</span
            ><el-link
              icon="el-icon-edit"
              @click="getUserInfo(scope.row)"
            ></el-link>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="所属供应商">
          <template slot-scope="scope">
            <span>{{ scope.row.supplierOwinName }}</span
            ><el-link
              icon="el-icon-edit"
              @click="openChangeSupplierDialog(scope.row)"
            ></el-link>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column prop="phone" label="电话"> </el-table-column>
        <el-table-column prop="company" label="公司"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              icon="el-icon-edit"
              @click="openUserAddressDialog(scope.row)"
              >地址编辑</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <!-- 修改客户名称对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="修改客户名称"
      :visible.sync="changeNameDialogVisible"
    >
      <el-form :model="changeNameForm">
        <el-form-item label="客户备注名称">
          <el-input v-model="changeNameForm.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="changeNameDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeNameDialogConfigm"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改客户所属供应商对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="修改客户所属供应商"
      :visible.sync="editSupplierDialogVisible"
    >
      <el-form :model="editSupplierForm">
        <el-form-item label="供应商">
          <el-select
            filterable
            @focus="onFocus"
            v-model="editSupplierForm.supplierId"
            placeholder="请选择供应商"
          >
            <el-option
              v-for="item in supplierOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editSupplierDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeSupplierDialogConfigm"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 客户地址详情 -->
    <el-dialog
      title="客户地址详情"
      :visible.sync="customerAddressDialogVisible"
    >
      <el-form :inline="true" :model="customerAddressForm">
        <el-form-item label="客户名称:">
          <el-input
            disabled
            v-model="customerAddressForm.userName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户别名:">
          <el-input
            disabled
            v-model="customerAddressForm.customerName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-row>
        <el-table
          :data="customerAddressForm.addressData"
          stripe
          style="width: 100%"
          border
        >
          <el-table-column type="index" label="#"> </el-table-column>
          <el-table-column prop="receiverName" label="联系人">
          </el-table-column>
          <el-table-column prop="receiverPhone" label="联系电话">
          </el-table-column>
          <el-table-column prop="receiverAddress" label="地址">
          </el-table-column>
          <el-table-column label="是否默认">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isDefault"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="switchUserDefaultAddress(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link
                icon="el-icon-edit"
                @click="openEditCustomerAddressDialog(scope.row)"
                title="编辑"
              ></el-link>
              <el-link
                icon="el-icon-delete"
                @click="deleteUserAddress(scope.row.id)"
                title="删除"
              ></el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 新增客户地址对话框 -->
      <el-dialog
        title="新增地址"
        append-to-body
        :visible.sync="addCustomerAddressDialogVisible"
        @close="resetForm('addCustomerAddressFormRef')"
      >
        <el-form
          :rules="addCustomerAddressFormRules"
          ref="addCustomerAddressFormRef"
          :model="addCustomerAddressForm"
        >
          <el-form-item prop="contactName" label="联系人:">
            <el-input
              v-model="addCustomerAddressForm.contactName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="联系电话:">
            <el-input
              v-model="addCustomerAddressForm.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="address" label="地址:">
            <el-input
              v-model="addCustomerAddressForm.address"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addCustomerAddressDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addCustomerAddressDialogConfim"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 编辑客户地址对话框 -->
      <!-- 新增客户地址对话框 -->
      <el-dialog
        title="编辑地址"
        append-to-body
        :visible.sync="editCustomerAddressDialogVisible"
      >
        <el-form
          :rules="editCustomerAddressFormRules"
          ref="editCustomerAddressFormRef"
          :model="editCustomerAddressForm"
        >
          <el-form-item prop="contactName" label="联系人:">
            <el-input
              v-model="editCustomerAddressForm.contactName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="联系电话:">
            <el-input
              v-model="editCustomerAddressForm.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="address" label="地址:">
            <el-input
              v-model="editCustomerAddressForm.address"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editCustomerAddressDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="editCustomerAddressDialogConfim"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <div slot="footer" class="dialog-footer">
        <el-button
          type="success"
          @click="addCustomerAddressDialogVisible = true"
          >新增地址</el-button
        >
        <el-button @click="customerAddressDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="customerAddressDialogVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import clip from '@/utils/clipboard'
import {
  getUserList,
  changeCustomerName,
  getUserAddressList,
  addUserContactDetail,
  editUserContactDetail,
  setUserDefaultAddress,
  deleteUserAddress,
  changeUserOwinSupplier,
} from '@/api/user.js'

import { getAll } from '@/api/supplier.js'

import { validatePhone } from '@/utils/validate.js'

import { iosOnFocus } from '@/utils/iosPlatform.js'

export default {
  data() {
    let validatePhoneRulse = (rule, value, callback) => {
      if (validatePhone(value)) {
        callback()
      } else {
        callback(new Error('当前手机号格式不正确'))
      }
    }
    return {
      supplierOptions: [],
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      // 用户 list 数据
      userListData: [],
      // 更改客户别名对话框显示隐藏
      changeNameDialogVisible: false,
      // 更改客户别名表单
      changeNameForm: {
        name: '',
      },
      // 客户地址
      customerAddressDialogVisible: false,
      customerAddressForm: {
        id: null,
        // 用户名
        userName: '',
        // 用户别名
        customerName: '',
        // 用户地址信息
        addressData: [],
      },
      // 新增客户地址部分
      addCustomerAddressDialogVisible: false,
      addCustomerAddressForm: {
        contactName: '',
        phone: '',
        address: '',
      },
      addCustomerAddressFormRules: {
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: validatePhoneRulse, trigger: 'blur' },
        ],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
      },
      // 编辑客户地址部分
      editCustomerAddressDialogVisible: false,
      editCustomerAddressForm: {
        id: null,
        contactName: '',
        phone: '',
        address: '',
      },
      editCustomerAddressFormRules: {
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: validatePhoneRulse, trigger: 'blur' },
        ],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
      },

      editSupplierDialogVisible: false,
      editSupplierForm: {
        userId: null,
        supplierId: null,
      },
    }
  },
  created() {
    this.getUserListData()
    this.getSupplierOptions()
  },
  methods: {
    async getSupplierOptions() {
      let { data } = await getAll()
      this.supplierOptions = data
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getUserListData()
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getUserListData()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getUserListData()
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    async getUserListData() {
      let { data } = await getUserList(this.queryFilter)

      this.userListData = data.data

      this.queryFilter.total = data.total
      // this.queryFilter.pageIndex = data.pageIndex
      // this.queryFilter.pageSize = data.pageSize
    },
    async switchUserState(userInfo) {
      console.log(userInfo)
      let { code } = await changeUserState({
        id: userInfo.id,
        state: userInfo.canLogin,
      })
      if (code != 200) {
        userInfo.canLogin = !userInfo.canLogin
        return this.$message.error('用户状态修改失败')
      }

      return this.$message.success('用户状态修改成功')
    },
    getUserInfo(row) {
      this.changeNameForm.id = row.id
      this.changeNameForm.name = row.customerName

      this.changeNameDialogVisible = true
    },
    // 更改客户别名确认按钮
    async changeNameDialogConfigm() {
      await changeCustomerName(this.changeNameForm.id, this.changeNameForm.name)

      this.getUserListData()

      this.changeNameDialogVisible = false

      return this.$message.success('客户名称更新成功')
    },
    // 打开客户地址详情对话框
    async openUserAddressDialog(row) {
      this.customerAddressForm.id = row.id
      this.customerAddressForm.userName = row.userName
      this.customerAddressForm.customerName = row.customerName

      console.log(row)

      let { data } = await getUserAddressList(row.id)

      this.customerAddressForm.addressData = data
      this.customerAddressDialogVisible = true
    },
    // 新增客户地址对话框确认
    async addCustomerAddressDialogConfim() {
      this.$refs.addCustomerAddressFormRef.validate(async (valid) => {
        if (valid) {
          await addUserContactDetail({
            userId: this.customerAddressForm.id,
            ...this.addCustomerAddressForm,
          })

          let { data } = await getUserAddressList(this.customerAddressForm.id)
          this.customerAddressForm.addressData = data

          this.$message.success('用户地址新增成功')

          this.addCustomerAddressDialogVisible = false
        } else {
          this.$message.error('当前表单信息填写不完整')
        }
      })
    },
    // 切换用户默认地址
    async switchUserDefaultAddress(row) {
      await setUserDefaultAddress(row.id)

      let { data } = await getUserAddressList(this.customerAddressForm.id)
      this.customerAddressForm.addressData = data
    },
    // 删除当前用户地址
    async deleteUserAddress(id) {
      this.$confirm(
        '当前操作将会将该用户地址从列表中删除,是否确认删除?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(async () => {
          await deleteUserAddress(id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })

          let { data } = await getUserAddressList(this.customerAddressForm.id)
          this.customerAddressForm.addressData = data
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 打开编辑对话框
    openEditCustomerAddressDialog(row) {
      this.editCustomerAddressForm.id = row.id
      this.editCustomerAddressForm.contactName = row.receiverName
      this.editCustomerAddressForm.phone = row.receiverPhone
      this.editCustomerAddressForm.address = row.receiverAddress

      this.editCustomerAddressDialogVisible = true
    },
    // 编辑用户地址对话框确认
    async editCustomerAddressDialogConfim() {
      this.$refs.editCustomerAddressFormRef.validate(async (valid) => {
        if (valid) {
          await editUserContactDetail({
            ...this.editCustomerAddressForm,
          })

          let { data } = await getUserAddressList(this.customerAddressForm.id)
          this.customerAddressForm.addressData = data

          this.$message.success('用户地址编辑成功')

          this.editCustomerAddressDialogVisible = false
        } else {
          this.$message.error('当前表单信息填写不完整')
        }
      })
    },
    openChangeSupplierDialog(row) {
      this.editSupplierForm.userId = row.id
      this.editSupplierForm.supplierId = row.supplierOwinId
      this.editSupplierDialogVisible = true
    },
    async changeSupplierDialogConfigm() {
      if (!this.editSupplierForm.userId || !this.editSupplierForm.supplierId) {
        return this.$message.error('当前信息不完整')
      }
      await changeUserOwinSupplier(this.editSupplierForm)

      this.$message.success('更改供应商信息成功')

      this.getUserListData()

      this.editSupplierDialogVisible = false
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
}
</script>
<style lang="scss" scope>
.el-select {
  width: 100%;
}
</style>
